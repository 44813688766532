import Api from '@/services/Api'

export default {
  create(params) {
    return Api().post('/api/contacts', params)
  },
  list() {
    return Api().get('/api/contacts')
  },
  delete(id) {
    return Api().delete('/api/contacts/' + id)
  },
  get(id) {
    return Api().get(`/api/contacts/${id}`)
  },
  update(id, params) {
    return Api().put(`/api/contacts/${id}`, params)
  },
  getAttachment(id) {
    return Api().get(`/api/contacts/${id}/attachment`)
  },
  fixedList() {
    return Api().get('/api/contacts/fixed')
  },
  getRecipients(id, query) {
    return Api().get(`/api/contacts/${id}/recipients`, { params: query })
  },
  deleteRecipient(groupId, recipientId) {
    return Api().delete(`/api/contacts/${groupId}/recipients/${recipientId}`)
  },
  addRecipient(groupId, param) {
    return Api().post(`/api/contacts/${groupId}/recipients`, param)
  },
  getAllRecipients(groupIds) {
    return Api().get('/api/contacts/recipients', { params: { groupIds: groupIds.join(',') } })
  },
}
