<template>
  <div class="animated fadeIn container sms-send-page">
    <h1>수신번호를 추가하여 문자를 발송하세요</h1>
    <h2 class="mt-2 color-skyblue">{{ availableSendCount | numberFormat }}건 발송가능</h2>
    <div class="mt-4 inner-container">
      <div>
        <div class="phone-wrap">
          <textarea class="inner-text" id="message" name="body" rows="4" v-model="message" placeholder="메시지를 입력해주세요"
            v-on:input="updateMessage" required></textarea>
          <button id="emoji-btn" @click="clickEmojiBtn" v-b-tooltip.hover title="이모티콘 추가"></button>
          <button id="img-btn" @click="clickImgBtn" v-b-tooltip.hover title="이미지 링크 발송"></button>
          <button id="url-btn" @click="clickUrlBtn" v-b-tooltip.hover title="짧은 도메인 변환"></button>
          <button id="ccc-btn" @click="clickCccBtn" v-b-tooltip.hover title="한글 도메인 변환"></button>
          <span class="color-skyblue inner-bytes">{{ contentBytes }}/{{ maxContentBytes }}</span>
        </div>
        <div id="emoji-picker" v-show="showEmojiPicker"></div>
        <SendStatusChart />
      </div>
      <div>
        <div class="card-wrap">
          <div class="card clipboard-card">
            <div class="card-header" @click="toggleCardOpen(0)">
              <p>직접 입력하여 수신번호 추가하기</p>
              <button class="toggle-btn"></button>
            </div>
            <div class="card-body">
              <textarea class="clipboard-textarea" name="" id="" cols="30" rows="7" placeholder="전화번호1
전화번호2
전화번호3
...
                
이 곳에 전화번호를 입력 후 '수신번호 추가하기' 버튼을 눌러주세요.
1만개 이하로 나눠서 발송하시는게 발송속도가 빠릅니다." v-model="clipboardText"></textarea>
              <button class="btn btn-primary" style="margin-top:10px;float:right;min-width:100px" @click="addClipboard">
                수신번호 추가하기
              </button>
            </div>
          </div>
          <div class="card dropzone-card">
            <div class="card-header" @click="toggleCardOpen(1)">
              <p>엑셀이나 메모장 파일로 수신번호 추가하기</p>
              <button class="toggle-btn"></button>
            </div>
            <div class="card-body">
              <div class="link-icon-wrap">
                <a href="/assets/sample.xlsx" download>
                  <img src="/img/icon_xlsx.webp" alt="XLSX" />
                  <span>엑셀 샘플 다운로드</span>
                </a>
                <a href="/assets/sample.txt" download>
                  <img src="/img/icon_txt.webp" alt="TXT" />
                  <span>메모장 샘플 다운로드</span>
                </a>
              </div>
              <div class="file-dropzone-wrap">
                <img src="/img/icon_drag.webp" />
                <p>파일을 드래그하거나 <span>클릭</span>해서 파일을 업로드하세요.</p>
                <p>1만개 이하의 파일이 발송속도가 빠릅니다.</p>
                <div class="file-dropzone"> </div>
              </div>
            </div>
          </div>
          <div class="card contact-card">
            <div class="card-header" @click="toggleCardOpen(2)">
              <p>주소록에서 불러와서 수신번호 추가하기</p>
              <button class="toggle-btn"></button>
            </div>
            <div class="card-body">
              <table class="contact-table">
                <tbody>
                  <tr v-for="contactGroup in contactGroups" :value="contactGroup.id" :key="contactGroup.id"
                    v-show="contactGroup.totalCount > 0" @click="toggleContactGroup(contactGroup.id)">
                    <td>
                      <input type="checkbox" :checked="isCheckedContactGroup(contactGroup.id)" readonly />
                    </td>
                    <td>{{ contactGroup.name }}</td>
                    <td>
                      {{ contactGroup.totalCount }}명
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="btn-wrap">
                <button class="btn btn-primary ml-2" style="min-width:100px" @click="addGroup">
                  선택한 그룹 추가
                </button>
                <button class="btn btn-primary ml-2" style="min-width:100px" @click="addAllGroup">
                  모든 그룹 추가
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-area">
        <p class="send-text" v-show="showGrid"><span>{{ gridRowCount | numberFormat }}</span>건 발송 예정</p>
        <table class="send-table">
          <tr v-show="showGrid">
            <td colspan="2" style="padding-left:0;padding-right:0;">
              <Grid id="tui-grid" ref="tuiGrid" :columns="gridProps.columns" :selectionUnit="gridProps.selectionUnit"
                :scrollX="gridProps.scrollX" :rowHeaders="gridProps.rowHeaders" :bodyHeight="200"
                @mousedown="gridMouseDown" @check="gridCheck" @uncheck="gridCheck" @checkAll="gridCheck"
                @uncheckAll="gridCheck" />
              <div class="delete-btn-wrap">
                <span @click="removeSelectedRecipients">선택한 번호 삭제</span>
                <span @click="removeAllRecipients">전체 삭제</span>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div style="display: flex;align-items: center;gap:5px;">
                <span>
                  고정 수신번호
                </span>
                <div class="custom-tooltip" style="float: left;">
                  <img src="/img/i_help.svg" style="display: inline-block;width:15px;object-fit:contain;opacity:0.4" />
                  <div class="tooltip-text">
                    자주 쓰는 수신번호(받을번호)를 저장할 수 있는 기능이에요.<br />
                    이 기능을 켜고 고객님의 번호를 넣으면 발송시마다 확인 문자를 받으실 수 있어요.
                  </div>
                </div>
              </div>
            </td>
            <td>
              <b-form-radio-group style="display: flex;white-space: nowrap;" v-model="useFixNumber">
                <b-form-radio value="1">사용</b-form-radio>
                <b-form-radio value="0">미사용</b-form-radio>
              </b-form-radio-group>
              <textarea v-show="useFixNumber == 1" v-model="fixRecipients" :style="fixRecipientsStyle"
                class="mt-2 recipient-number" rows="4" required @focus="focusRecipientsInput"
                @blur="blurfRecipientsInput"></textarea>
            </td>
          </tr>
          <tr>
            <td>번호 중복 제거</td>
            <td>
              <b-form-radio-group style="display: flex;" v-model="useDeleteDuplicate">
                <b-form-radio value="1">사용</b-form-radio>
                <b-form-radio value="0">미사용</b-form-radio>
              </b-form-radio-group>
            </td>
          </tr>
          <tr>
            <td>예약 발송</td>
            <td>
              <b-form-radio-group style="display: flex;" v-model="useReservation">
                <b-form-radio value="1">사용</b-form-radio>
                <b-form-radio value="0">미사용</b-form-radio>
              </b-form-radio-group>
              <div v-show="useReservation == 1" class="tui-datepicker-input tui-datetime-input tui-has-focus"
                style="margin-top:10px">
                <input type="text" id="tui-date-picker-target" placeholder="날짜를 선택해주세요" v-model="reservationDateText"
                  @click="clickReservationDateInput" />
                <span class="tui-ico-date"></span>
              </div>
              <div v-show="showReservationDatePicker" id="tui-date-picker-container" class="reservation-picker"
                style="margin-top: -1px;z-index:999"></div>
            </td>
          </tr>
          <tr>
            <td>분할 발송</td>
            <td>
              <b-form-radio-group style="display: flex;" v-model="useSplit">
                <b-form-radio value="1">사용</b-form-radio>
                <b-form-radio value="0">미사용</b-form-radio>
              </b-form-radio-group>
              <div v-show="useSplit == 1" class="split-select-wrap">
                <div>
                  <select style="flex-flow:1" class="form-control" v-model="splitCount">
                    <option value="1">1건</option>
                    <option value="10">10건</option>
                    <option value="100">100건</option>
                    <option value="200">200건</option>
                    <option value="300">300건</option>
                    <option value="500">500건</option>
                    <option value="1000">1,000건</option>
                    <option value="2000">2,000건</option>
                    <option value="3000">3,000건</option>
                    <option value="4000">4,000건</option>
                    <option value="5000">5,000건</option>
                  </select>
                  <span>씩</span>
                </div>
                <div>
                  <select style="flex-flow:1" class="form-control" v-model="splitTime">
                    <option value="0.1" v-show="user && user.canOneSecSplit">1초</option>
                    <option value="1">1분</option>
                    <option value="3">3분</option>
                    <option value="5">5분</option>
                    <option value="10">10분</option>
                    <option value="30">30분</option>
                    <option value="60">60분</option>
                  </select>
                  <span>간격으로 발송</span>
                </div>
              </div>
            </td>
          </tr>
        </table>
        <b-btn ref="sendBtn" variant="primary" class="float-left btn-primary send-btn" @click="createSmsRequest"
          :disabled="isLoading">
          <span v-if="!isLoading">발송</span>
          <pulse-loader :loading="isLoading" :color="loaderStyle.color" :size="loaderStyle.size"></pulse-loader>
        </b-btn>
      </div>
    </div>
    <b-modal ref="sendCompleteModal" title="메시지 전송 완료" ok-only ok-title="확인" centered>
      발송: <span class="color-skyblue">{{ sendResponse.totalCount }}</span> 건<br />
    </b-modal>
    <ImgUploadPopup v-if="showImgPopup" :key="popupKey" @use-link="useLink" @close="closeImgUploadPopup" />
    <UrlPopup v-if="showUrlPopup" :key="popupKey" @use-link="useLink" @close="closeUrlPopup" />
    <CccPopup v-if="showCccPopup" :key="popupKey" @use-link="useLink" @close="closeCccPopup" />
  </div>
</template>

<script>
import ContactService from '@/services/ContactService'
import SmsService from '@/services/SmsService'
import { Grid } from '@toast-ui/vue-grid'
import TuiGrid from 'tui-grid'
import { PulseLoader } from 'vue-spinner/dist/vue-spinner.min.js'
import { mapGetters } from 'vuex'
import SendStatusChart from '@/views/components/SendStatusChart'
import ImgUploadPopup from '@/views/components/ImgUploadPopup'
import UrlPopup from '@/views/components/UrlPopup'
import CccPopup from '@/views/components/CccPopup'

import moment from 'moment'

TuiGrid.setLanguage('ko', {
  display: {
    noData: '발송예정인 수신번호가 없습니다.\n왼쪽 도구에서 수신번호를 추가해주세요.',
  },
});
TuiGrid.applyTheme('default', {
  selection: {
    background: '#e5f6ff',
  },
  scrollbar: {
    background: '#f5f5f5',
    thumb: '#d9d9d9',
    active: '#c1c1c1'
  },
  cell: {
    normal: {
      background: '#fff',
      border: '#cccccc',
      showVerticalBorder: true,
      showHorizontalBorder: true
    },
    focused: {
      background: '#e5f6ff',
    },
    header: {
      background: '#f0f3f5',
      border: '#cccccc'
    },
    rowHeader: {
      background: '#fff',
      border: '#cccccc',
      showVerticalBorder: true
    },
    selectedHead: {
      background: '#0b3f73',
    },
    selectedHeader: {
      background: '#f0f3f5',
      border: '#cccccc'
    }
  }
});

export default {
  name: 'SmsSend',
  head: {
    link: [
      { rel: 'stylesheet', href: 'https://unpkg.com/dropzone@6.0.0-beta.1/dist/dropzone.css' },
      { rel: 'stylesheet', href: 'https://uicdn.toast.com/grid/latest/tui-grid.css' },
    ],
    script: [
      { type: 'text/javascript', src: 'https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.18.4/xlsx.full.min.js', async: false, body: true },
      { type: 'text/javascript', src: 'https://unpkg.com/dropzone@6.0.0-beta.1/dist/dropzone-min.js', async: false, body: true },
    ]
  },
  components: {
    PulseLoader,
    Grid,
    SendStatusChart,
    ImgUploadPopup,
    UrlPopup,
    CccPopup
  },
  data() {
    return {
      availableSendCount: 0,
      message: '',
      clipboardText: '',
      selectedGroups: [],
      selectedGroupIds: [],
      showGrid: true,
      grid: null,
      gridMouseDragging: false,
      gridProps: {
        rowHeaders: ['checkbox'],
        selectionUnit: 'row',
        scrollX: false,
        columns: [{
          header: '수신번호',
          name: 'recipient',
          minWidth: 130
        }, {
          header: '#1',
          name: 'data1',
          hidden: true,
        }, {
          header: '#2',
          name: 'data2',
          hidden: true
        }, {
          header: '#3',
          name: 'data3',
          hidden: true
        }, {
          header: '#4',
          name: 'data4',
          hidden: true
        }]
      },
      gridRowCount: 0,
      contactGroups: [],
      selectedGroupId: '',
      recipientsPlaceholder: '전화번호1\n전화번호2\n전화번호3\n...',
      recipients: '전화번호1\n전화번호2\n전화번호3\n...',
      recipientsStyle: {
        color: '#999',
      },
      fixRecipients: '전화번호1\n전화번호2\n전화번호3\n...',
      fixRecipientsStyle: {
        color: '#999',
      },
      isLoading: false,
      loaderStyle: {
        color: 'rgba(255, 255, 255, 0.7)',
        size: '8px',
      },
      files: null,
      sendResponse: {
        totalCount: '',
        paidMoney: '',
        holdMoney: '',
      },
      useFixNumber: 0,
      useDeleteDuplicate: 0,
      useReservation: 0,
      showReservationDatePicker: false,
      useSplit: 0,
      tempReservationDate: null,
      reservationDate: null,
      reservationDateText: null,
      minReservationDate: null,
      maxReservationDate: null,
      splitCount: 1,
      splitTime: 1,
      showEmojiPicker: false,
      showImgPopup: false,
      showUrlPopup: false,
      showCccPopup: false,
      popupKey: 1
    }
  },
  mounted() {
    document.body.classList.add('bg-white')
    this.initDropzone();
    this.initDatePicker();
    this.initEmojiPicker();
    this.initGrid();

    this.getUserInfo()
    this.getContactList()
    this.getContactGroupList()

    setTimeout(() => {
      this.initCardAnimation();
    }, 300)

    this.toggleCardAnimation();
  },
  methods: {
    initDropzone() {
      const t = this;
      if (!window.Dropzone) {
        setTimeout(function () {
          t.initDropzone()
        }, 500)
        return
      }

      const dropzone = new window.Dropzone("div.file-dropzone", {
        autoQueue: false,
        url: "/file/fake",
        acceptedFiles: ".csv,.xlsx,.txt",
        maxFiles: 1,
        init: function () {

          this.on('addedfile', function (file) {
            if (!file) {
              return
            }

            const lowerFileName = file.name.toLowerCase();
            const reader = new FileReader();

            if (lowerFileName.endsWith('.xlsx')) {
              reader.onload = function (e) {
                const arrayBuffer = e.target.result;
                const XLSX = window.XLSX

                // XLSX 파일을 파싱
                const workbook = XLSX.read(arrayBuffer, { type: 'array' });
                const sheetName = workbook.SheetNames[0]; // 첫 번째 시트를 사용

                // 시트 데이터 가져오기
                const sheet = workbook.Sheets[sheetName];
                const xlsxData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

                const filteredData = xlsxData.filter(row => row[0] !== undefined && row[0] !== null && row[0] !== '');

                // 헤더 행 제거
                // if (filteredData.length > 0) {
                //   filteredData.shift();
                // }

                const formattedData = filteredData.map(row => {
                  const recipient = row[0];
                  const data = {};

                  for (let i = 1; i < row.length; i++) {
                    data[`data${i}`] = row[i];
                  }

                  return { recipient, ...data };
                });

                const tuiGrid = t.$refs.tuiGrid;
                tuiGrid.invoke("appendRows", formattedData);
                t.updateGridRowCount();
              };

              // XLSX 파일을 배열 버퍼로 읽기
              reader.readAsArrayBuffer(file);
            }
            else {
              reader.onload = function (e) {
                const csvText = e.target.result;
                const csvData = t.parseCSV(csvText);

                const tuiGrid = t.$refs.tuiGrid;
                tuiGrid.invoke("appendRows", csvData);
                t.updateGridRowCount();
              };

              // CSV 파일을 텍스트로 읽기
              reader.readAsText(file);
            }

            dropzone.removeFile(file);
          });
        }
      });
    },
    initDatePicker() {
      const t = this;
      if (!window.tui || !window.tui.DatePicker || !window.tui.TimePicker) {
        setTimeout(function () {
          t.initDatePicker()
        }, 500)
        return
      }

      const minReservationDate = new Date().toISOString()

      var maxDate = new Date()
      maxDate.setDate(maxDate.getDate() + 7)
      const maxReservationDate = maxDate.toISOString()

      var coeff = 1000 * 60 * 5;
      const now = new Date()
      const targetDate = new Date(Math.ceil(now.getTime() / coeff) * coeff);
      t.tempReservationDate = targetDate

      const datePicker = new window.tui.DatePicker('#tui-date-picker-container', {
        selectableRanges: [
          [minReservationDate, maxReservationDate],
        ],
        date: targetDate,
        timePicker: {
          language: 'ko',
          meridiemPosition: 'left',
          minuteStep: 5,
          date: targetDate,
          // inputType: 'spinbox',
        },
        showAlways: true,
        language: 'ko'
      });

      const pickerBtnWrap = document.createElement('div');
      pickerBtnWrap.classList.add('tui-datepicker-footer');
      pickerBtnWrap.classList.add('btn-wrap');
      pickerBtnWrap.innerHTML = '<button class="btn-primary btn-ok">확인</button><button class="btn-secondary btn-cancel ml-10">취소</button>';
      pickerBtnWrap.querySelector('.btn-ok').addEventListener('click', () => {
        t.showReservationDatePicker = false

        t.reservationDate = t.tempReservationDate

        const m = moment(t.tempReservationDate)
        t.reservationDateText = m.format('YYYY-MM-DD') + (m.hour() < 12 ? ' 오전 ' : ' 오후 ') + m.format('hh:mm');
      })

      pickerBtnWrap.querySelector('.btn-cancel').addEventListener('click', () => {
        t.showReservationDatePicker = false
      })

      document.querySelector('.tui-datepicker-footer').after(pickerBtnWrap);

      datePicker.on('change', () => {
        t.tempReservationDate = datePicker.getDate()
      })
    },
    initEmojiPicker() {
      try {
        const t = this
        const pickerOptions = {
          set: 'apple',
          i18n: {
            "search": "검색",
            "search_no_results_1": "어머나!",
            "search_no_results_2": "결과를 찾을 수 없어요",
            "pick": "이모지 선택하기",
            "add_custom": "이모지 추가하기",
            "categories": {
              "activity": "활동",
              "custom": "커스텀",
              "flags": "깃발",
              "foods": "음식 및 음료",
              "frequent": "자주 사용하는 항목",
              "nature": "동물 및 자연",
              "objects": "사물",
              "people": "스마일리 및 사람",
              "places": "여행 및 장소",
              "search": "검색 결과",
              "symbols": "기호"
            },
            "skins": {
              "1": "표준",
              "2": "밝은 피부색",
              "3": "약간 밝은 피부색",
              "4": "중간 피부색",
              "5": "약간 어두운 피부색",
              "6": "어두운 피부색",
              "choose": "기준 피부색 선택하기"
            }
          },
          onEmojiSelect: function (emoji) {
            t.message = t.message + emoji.native
          },
          onClickOutside: function () {
            t.showEmojiPicker = false
          }
        }
        const picker = new window.EmojiMart.Picker(pickerOptions)
        document.getElementById('emoji-picker').appendChild(picker);
      }
      catch (e) {
        // do nothing
      }
    },
    initGrid() {
      const t = this
      document.body.addEventListener('mouseup', function () {
        if (t.gridMouseDragging) {
          const tuiGrid = t.$refs.tuiGrid;
          const selectionRange = tuiGrid.invoke("getSelectionRange", null);
          if (!selectionRange) {
            return
          }

          const startRowIndex = selectionRange.start[0]
          const endRowIndex = selectionRange.end[0]

          if (startRowIndex === endRowIndex) {
            return
          }

          const startRow = tuiGrid.invoke("getRowAt", startRowIndex);
          const endRow = tuiGrid.invoke("getRowAt", endRowIndex);
          tuiGrid.invoke("checkBetween", startRow.rowKey, endRow.rowKey);
        }

        t.gridMouseDragging = false
      })
    },
    updateGridRowCount() {
      const tuiGrid = this.$refs.tuiGrid;
      const gridRowCount = tuiGrid.invoke("getRowCount", null);


      if (this.gridRowCount === 0 && gridRowCount > 0) {
        this.showGrid = true


        const row = tuiGrid.invoke("getRowAt", 0);
        if (row['data1']) {
          tuiGrid.invoke("showColumn", 'data1')

          if (row['data2']) {
            tuiGrid.invoke("showColumn", 'data2')
          }
          else {
            tuiGrid.invoke("hideColumn", 'data2')
          }
        }
        else {
          tuiGrid.invoke("hideColumn", 'data1')
          tuiGrid.invoke("hideColumn", 'data2')
        }
      }

      this.gridRowCount = gridRowCount;
    },
    gridMouseDown() {
      this.gridMouseDragging = true
    },
    gridCheck() {
      const tuiGrid = this.$refs.tuiGrid;
      for (let i = 0; i < this.gridRowCount; i++) {
        const row = tuiGrid.invoke("getRowAt", i);
        if (row['_attributes'].checked) {
          tuiGrid.invoke("addRowClassName", String(row.rowKey), 'tui-grid-row-checked');
        }
        else {
          tuiGrid.invoke("removeRowClassName", String(row.rowKey), 'tui-grid-row-checked');
        }
      }
    },
    removeSelectedRecipients() {
      const tuiGrid = this.$refs.tuiGrid;
      tuiGrid.invoke("removeCheckedRows", false);
      this.updateGridRowCount()
    },
    removeAllRecipients() {
      const tuiGrid = this.$refs.tuiGrid;
      tuiGrid.invoke("resetData", []);
      this.updateGridRowCount()
    },
    isCheckedContactGroup(groupId) {
      return this.selectedGroupIds.includes(groupId);
    },
    toggleContactGroup(groupId) {
      const index = this.selectedGroupIds.indexOf(groupId);
      const newArray = [...this.selectedGroupIds];
      if (index !== -1) {
        newArray.splice(index, 1);
      } else {
        newArray.push(groupId);
      }

      this.selectedGroupIds = newArray;
    },
    parseCSV(csvText) {
      const lines = csvText.split('\n');
      const csvData = [];

      for (let i = 0; i < lines.length; i++) {
        const values = lines[i].split(',');
        const rowData = {};

        for (let j = 0; j < values.length; j++) {
          let key = j
          if (j === 0) {
            key = 'recipient'
          }
          else {
            key = `data${j}`
          }
          rowData[key] = values[j] ? values[j].trim() : '';
        }

        csvData.push(rowData);
      }

      return csvData;
    },
    addClipboard() {
      const rows = this.clipboardText.split('\n')
      let items = []
      for (const row of rows) {
        const cols = row.split(',')
        let item = {}
        for (const key in cols) {
          const value = cols[key]
          if (key == 0) {
            item['recipient'] = this.getNumberFormat(value)
          }
          else {
            item['data' + key] = value;
          }
        }

        if (item['recipient']) {
          items.push(item)
        }
      }

      if (items.length == 0) {
        alert('수신번호를 입력해주세요')
        return;
      }

      const tuiGrid = this.$refs.tuiGrid;
      tuiGrid.invoke("appendRows", items);
      this.updateGridRowCount();
      this.clipboardText = '';
    },
    clickReservationDateInput() {
      this.showReservationDatePicker = true
    },
    async addGroup() {

      try {
        const response = await ContactService.getAllRecipients(this.selectedGroupIds)
        const list = response.data.list
        const rows = list;
        let items = []
        for (const row of rows) {
          let item = {}

          item['recipient'] = this.getNumberFormat(row['recipient'])
          item['data1'] = row['info']
          items.push(item)
        }

        if (items.length == 0) {
          alert('수신번호를 입력해주세요')
          return;
        }

        const tuiGrid = this.$refs.tuiGrid;
        tuiGrid.invoke("appendRows", items);
        this.updateGridRowCount();
        this.selectedGroupIds = []
      }
      catch (e) {
        this.selectedGroupIds = []
      }
    },
    addAllGroup() {
      let selectedGroupIds = []
      for (const group of this.contactGroups) {
        selectedGroupIds.push(group.id)
      }
      this.selectedGroupIds = selectedGroupIds


      this.addGroup();
    },
    initCardAnimation() {
      const gsap = window.gsap
      const items = document.querySelectorAll('.sms-send-page .card')

      const isClosed1 = this.$cookie.get('londonsms_send_close_card_0') !== 'true'
      const isClosed2 = this.$cookie.get('londonsms_send_close_card_1') !== 'true'
      const isClosed3 = this.$cookie.get('londonsms_send_close_card_2') !== 'true'
      if (!isClosed1 || !isClosed2 || !isClosed3) {
        items.forEach((item, index) => {
          if (index === 0 && isClosed1) {
            item.classList.add('closed')
            return
          }
          if (index === 1 && isClosed2) {
            item.classList.add('closed')
            return
          }
          if (index === 2 && isClosed3) {
            item.classList.add('closed')
            return
          }

          item.classList.remove('closed')
          gsap.to(item, {
            height: 'auto',
            duration: 0.3
          })
        })
      }
      else {
        gsap.to(items, {
          height: 'auto',
          duration: 0.8,
          ease: 'elastic(1, .95)'
        })

        items.forEach((item, index) => {
          if (index === 0) {
            return
          }

          gsap.to(item, {
            height: '54px',
            duration: 0.8,
            ease: 'elastic(1, .95)',
            delay: 0.8,
            onStart: function () {
              item.classList.add('closed')
            }
          })
        })
      }
    },
    toggleCardAnimation() {
      const gsap = window.gsap
      const items = document.querySelectorAll('.sms-send-page .card')

      items.forEach((item, i) => {
        item.querySelector('.sms-send-page .card-header').addEventListener('click', () => {
          items.forEach((it, ind) => {
            if (i === ind) {
              it.classList.toggle('closed')
              const isClosed = it.classList.contains('closed')
              gsap.to(item, {
                height: isClosed ? '54px' : 'auto',
                duration: 0.6,
                ease: 'elastic(1, .8)'
              })
            }
          })
        })
      })
    },
    toggleCardOpen(index) {
      const isClosed = document.querySelectorAll('.sms-send-page .card')[index].classList.contains('closed')
      this.$cookie.set('londonsms_send_close_card_' + index, isClosed)
    },
    clickEmojiBtn() {
      const t = this
      setTimeout(() => {
        t.showEmojiPicker = true
      }, 50)
    },
    clickImgBtn() {
      this.showImgPopup = true
      this.popupKey += 1
      document.body.style.overflow = 'hidden';
    },
    closeImgUploadPopup() {
      this.showImgPopup = false
      document.body.style.overflow = 'auto';
    },
    clickUrlBtn() {
      this.showUrlPopup = true
      this.popupKey += 1
      document.body.style.overflow = 'hidden';
    },
    clickCccBtn() {
      this.showCccPopup = true
      this.popupKey += 1
      document.body.style.overflow = 'hidden';
    },
    closeUrlPopup() {
      this.showUrlPopup = false
      document.body.style.overflow = 'auto';
    },
    closeCccPopup() {
      this.showCccPopup = false
      document.body.style.overflow = 'auto';
    },
    parseRecipient(recipients) {
      recipients = recipients.trim()
      recipients = recipients.replace(/\n\n+/g, '\n')
      recipients = recipients.replace(/[ \t\r]+/g, '')
      recipients = recipients.replace(/\n/g, ',')

      return recipients
    },
    parseFixedRecipients() {
      let recipients = this.fixRecipients
      if (recipients == this.recipientsPlaceholder) {
        return []
      }

      recipients = recipients.trim()
      recipients = recipients.replace(/\n\n+/g, '\n')
      recipients = recipients.replace(/[ \t\r]+/g, '')

      return recipients.split('\n')
    },
    async getAllrecipientItems() {
      try {
        const tuiGrid = this.$refs.tuiGrid;

        let recipientItems = []
        for (let i = 0; i < this.gridRowCount; i++) {
          const row = tuiGrid.invoke("getRowAt", i);
          let data = [row['recipient']]
          let keys = ['data1', 'data2', 'data3', 'data4'];
          for (const key of keys) {
            if (!row.hasOwnProperty(key)) {
              break;
            }
            data.push(row[key]);
          }
          recipientItems.push(data)
        }

        return recipientItems
      } catch (error) {
        alert('파일 읽기 실패. 관리자에게 문의해주세요')
        return []
      }
    },
    refineRecipientItems(items) {
      if (!items) {
        return []
      }

      let refineItems = [];
      let seen = new Set();

      const useTemplate = this.message.indexOf('#1') >= 0

      items.forEach(row => {
        try {
          let phoneNumber = String(row[0]).replace(/[^0-9]/g, '');
          if (phoneNumber.length === 0) {
            return;
          }

          if (this.useDeleteDuplicate == '1') {
            if (seen.has(phoneNumber)) {
              return;
            }

            seen.add(phoneNumber);
          }

          if (useTemplate && Array.isArray(row)) {
            refineItems.push(row.join(','));
          }
          else {
            refineItems.push(row && row.length > 0 ? row[0] : row);
          }
        }
        catch (e) {
          console.log(e)
          // do nothing
        }
      })

      return refineItems;
    },
    async createSmsRequest() {

      var fixRecipients = []
      if (this.useFixNumber == '1') {
        fixRecipients = this.parseFixedRecipients()
      }

      if (this.contentBytes <= 0) {
        alert('메시지를 입력해주세요')
        return
      } else if (this.contentBytes > this.maxContentBytes) {
        alert(`메시지는 ${this.maxContentBytes}까지만 가능합니다`)
        return
      }

      var formData = new FormData()
      formData.append('message', this.message)
      formData.append('useFixNumber', this.useFixNumber == '1' ? 1 : 0)
      formData.append('useDeleteDuplicate', this.useDeleteDuplicate == '1' ? 1 : 0)
      let recipientItems = await this.getAllrecipientItems()

      if (this.useFixNumber == '1' && fixRecipients.length > 0) {
        formData.append('fixRecipients', fixRecipients.join(','))
        recipientItems = fixRecipients.map(item => [item]).concat(recipientItems)
      }

      if (recipientItems.length > 0) {
        var refineItems = this.refineRecipientItems(recipientItems)
        formData.append('recipientItems', JSON.stringify(refineItems))
      }

      if (this.useReservation == 1) {
        if (this.reservationDate.length == 0) {
          alert('예약 시간을 입력해주세요')
          return
        }

        if (moment().isAfter(this.reservationDate)) {
          alert('예약 시간이 현재 시간보다 과거입니다. 다시 설정해주세요.')
          return
        }

        formData.append('reservationDate', this.reservationDate)
      }

      if (this.useSplit == 1) {
        formData.append('splitCount', this.splitCount)
        formData.append('splitTime', this.splitTime);
      }

      if (this.isLoading) {
        return
      }
      this.isLoading = true

      const t = this
      SmsService.createSmsRequest(formData)
        .then(function (response) {
          if (response.status !== 200) {
            return
          }
          t.sendResponse = response.data
          t.getUserInfo()
          t.removeAllRecipients();
          t.$refs.sendCompleteModal.show()
          setTimeout(function () {
            t.isLoading = false
          }, 200)
        })
        .catch(function (e) {
          console.log(e)
          t.isLoading = false
        })
    },
    focusRecipientsInput(e) {
      const t = e.target
      t.style.color = '#333'
      if (this.recipientsPlaceholder == t.value) {
        t.value = ''
      }
    },
    blurfRecipientsInput(e) {
      const t = e.target
      if (t.value.length == 0) {
        t.value = this.recipientsPlaceholder
        t.style.color = '#999'
      }
    },
    updateMessage(e) {
      this.message = e.target.value
    },
    async getContactList() {
      try {
        const response = await ContactService.fixedList()
        const list = response.data.list

        if (list.length > 0) {
          this.fixRecipients = list[0].recipients.replace(/,/g, '\n')
          this.fixRecipientsStyle = { color: '#333' }
        }
      } catch (e) {
        // Do nothing
      }
    },
    async getContactGroupList() {
      try {
        const response = await ContactService.list()
        this.contactGroups = response.data.list
      } catch (e) {
        // Do nothing
      }
    },
    getBytes(contents) {
      let char;
      let charCount = 0;

      for (var k = 0; k < contents.length; k++) {
        char = contents.charAt(k);
        if (escape(char).length > 4)
          charCount += 2;
        else
          charCount++;
      }

      return charCount;
    },
    getNumberFormat(phoneNumber) {
      if (!phoneNumber) {
        return '';
      }

      let value = phoneNumber.replace(/\D/g, '');
      value = String(value)
      if (!value || value.length < 10) {
        return ''
      }

      value = value.replace(/-/g, '')
      if (value.startsWith('82')) {
        value = '0' + value.substring(2)
      }

      if (value.startsWith('10')) {
        value = '0' + value
      }

      if (value.length > 10) {
        value = value.substring(0, 3) + '-' + value.substring(3, 7) + '-' + value.substring(7)
      }
      else {
        value = value.substring(0, 3) + '-' + value.substring(3, 6) + '-' + value.substring(6, 10)
      }
      return value
    },
    useLink(link) {
      let message = this.message;
      if (message.trim().length > 0) {
        message += '\n';
      }
      message += link

      this.message = message;
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    contentBytes: function () {
      var message = this.message.replace(/(\r\n|\r|\n)/g, ' ')
      return message.length
    },
    maxContentBytes: function () {
      if (!this.user) {
        return 0
      }

      return 70;
    }
  },
  watch: {
    user() {
      this.availableSendCount = parseInt(this.user.money / this.user.smsFee)
      this.useFixNumber = this.user.useFixNumber == 1 ? '1' : '0'
      this.useDeleteDuplicate = this.user.useDeleteDuplicate == 1 ? '1' : '0'
    },
    isLoading() {
      this.$refs.sendBtn.disabled = this.isLoading
    },
    maxContentBytes() { },
    contacts() { },
    gridRowCount() {
      const tuiGrid = this.$refs.tuiGrid;
      setTimeout(() => {
        tuiGrid.invoke("refreshLayout", null);
      }, 20)
    }
  },
}
</script>

<style scoped lang="scss">
.sms-send-page {
  h2 {
    font-size: 50px;
    font-weight: 300
  }

  .inner-container {
    display: flex;

    >div:nth-of-type(1) {
      width: 256px;
      z-index: 9;
    }

    >div:nth-of-type(2) {
      margin: 0 40px 0 35px;
      width: 455px;
    }

    >div:nth-of-type(3) {
      width: 320px;
    }
  }

  .phone-wrap {
    position: relative;
    width: 256px;
    height: 523px;
    background: url('/img/phone.webp') center no-repeat;

    .inner-text {
      position: absolute;
      top: 65px;
      left: 18px;
      width: 220px;
      bottom: 65px;
      padding: 20px 15px;
      color: #000;
      line-height: 20px;
      font-size: 15px;
      font-weight: 300;
      outline: none;
      letter-spacing: 1;
    }

    .inner-bytes {
      position: absolute;
      right: 33px;
      bottom: 73px;
    }
  }

  #emoji-btn {
    position: absolute;
    left: 28px;
    bottom: 75px;
    width: 24px;
    height: 24px;
    border: none;
    outline: none;
    font-size: 24px;
    background: url(/img/smsSend/icon/send_emoji.png) center no-repeat;
    background-size: contain;
    overflow: hidden;
    opacity: 0.4;
  }

  #img-btn {
    position: absolute;
    left: 62px;
    bottom: 75px;
    width: 24px;
    height: 24px;
    border: none;
    outline: none;
    font-size: 24px;
    background: url(/img/smsSend/icon/send_img.png) center no-repeat;
    background-size: contain;
    opacity: 0.4;
  }

  #url-btn {
    position: absolute;
    left: 96px;
    bottom: 75px;
    width: 24px;
    height: 24px;
    border: none;
    outline: none;
    font-size: 24px;
    background: url(/img/smsSend/icon/send_url.png) center no-repeat;
    background-size: contain;
    opacity: 0.4;
  }

  #ccc-btn {
    position: absolute;
    left: 130px;
    bottom: 75px;
    width: 24px;
    height: 24px;
    border: none;
    outline: none;
    font-size: 24px;
    background: url(/img/smsSend/icon/send_ccc.png) center no-repeat;
    background-size: contain;
    opacity: 0.4;
  }

  #emoji-picker {
    margin-top: -72px;
    margin-left: 20px;
  }

  .card-wrap {
    border-radius: 20px;
    border: 1px solid var(--color-grey-200);
    overflow: hidden;

    .card {
      margin-bottom: 0;
      border-radius: 0;
      border: none;
      height: 54px;
      overflow: hidden;

      .card-header {
        position: relative;
        border: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        p {
          margin: 0;
          font-weight: 500;
        }

        .toggle-btn {
          position: relative;
          border: none;
          outline: none;
          width: 30px;
          height: 30px;
          background: none;

          &::before {
            display: block;
            content: ' ';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 16px;
            height: 2px;
            transform: translate(-50%, -50%);
            border-radius: 1px;
            background-color: var(--color-flat-black);
          }

          &::after {
            content: ' ';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 2px;
            height: 16px;
            transform: translate(-50%, -50%);
            border-radius: 1px;
            background-color: var(--color-flat-black);
            display: none;
          }
        }
      }

      .card-body {
        padding: 15px 10px;
        overflow: hidden;
      }

      &.closed {
        .toggle-btn::after {
          display: block;
        }
      }
    }


    .clipboard-card {
      .clipboard-textarea {
        width: 100%;
        border: none;

        &:focus {
          border: 1px solid var(--color-grey-200);
        }
      }
    }

    .dropzone-card {
      .link-icon-wrap {
        display: flex;
        gap: 30px;

        a {
          display: flex;
          gap: 5px;
          color: var(--color-flat-black);
          align-items: center;
        }

        img {
          transform: scale(0.8)
        }
      }

      .file-dropzone-wrap {
        position: relative;

        .file-dropzone {
          positioN: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        gap: 5px;
        margin-top: 20px;
        width: 100%;
        height: 150px;
        border: 1px dashed var(--color-grey-200);
        padding: 20px 10px;

        p {
          text-align: center;
          font-size: 13px;
          margin: 0;

          &:first-of-type {
            margin-top: 10px;
            font-weight: 600;

            span {
              color: var(--color-primary);
            }
          }
        }
      }
    }

    .contact-card {
      .contact-table {
        display: block;
        max-height: 200px;
        overflow-y: auto;

        tr:hover {
          background: var(--color-grey-50);
        }

        td {
          border-bottom: 1px solid var(--color-grey-100);
          font-size: 15px;
          line-height: 30px;

          &:first-of-type {
            min-width: 50px;
            text-align: center;
          }

          &:nth-of-type(2) {
            width: 100%;
            color: var(--color-flat-black);
            font-weight: 500;
          }

          &:nth-of-type(3) {
            min-width: 100px;
            color: var(--color-grey-500);
            text-align: center;
          }
        }
      }

      .btn-wrap {
        margin-top: 15px;
        text-align: right;
      }
    }
  }

  .right-area {
    .send-text {
      margin: 0;
      font-size: 15px;
      font-weight: 500;

      span {
        font-weight: 600;
        color: var(--color-primary);
        font-size: 17px;
      }
    }

    .send-table {
      width: 100%;

      >tr {
        border-top: none;
        border-bottom: 1px solid #ebebeb;

        >td {
          vertical-align: top;
          padding: 15px 5px;

          &:first-of-type {
            width: 125px;
          }
        }
      }

      .delete-btn-wrap {
        margin: 10px 0;
        width: 100%;
        text-align: right;

        span {
          margin-left: 20px;
          color: var(--color-grey-600);
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .recipient-number {
      border: none;
      outline: none;
      width: 100%;
      height: 100px;
    }

    .split-select-wrap {
      white-space: nowrap;
      display: flex;
      flex-flow: column;
      gap: 10px;
      margin-top: 10px;

      >div {
        display: flex;
        gap: 10px;
        align-items: center;

        span {
          margin-right: 10px;
        }

        select {
          max-width: 100px;
        }

      }
    }

    .send-btn {
      display: block;
      margin-top: 40px;
      margin-left: 50%;
      transform: translateX(-50%);
      width: 180px;
      height: 40px;
      border-radius: 0.25rem;
    }
  }
}


.custom-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;

  .tooltip-text {
    position: absolute;
    background-color: #fff;
    color: #333;
    border-radius: 6px;
    padding: 15px;
    box-sizing: border-box;
    width: 380px;

    font-size: 13px;
    top: 30px;
    left: 50%;
    transition: opacity 0.3s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    z-index: 100;
    // &::after {
    //   content: "";
    //   position: absolute;
    //   top: 100%;
    //   left: 50%;
    //   margin-left: -5px;
    //   border-width: 5px;
    //   border-style: solid;
    //   border-color: #fff transparent transparent transparent;
    // }
  }


  &:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
}
</style>
