<template>
  <div id="chart-wrap">
    <div id="container-speed" class="chart-container"></div>
    <p id="server-status-sub-msg">불러오는 중</p>
  </div>
</template>
<script>
export default {
  mounted() {
    this.createStatusChart();
  },
  data() {
    return {
      chartSpeed: null,
      gaugeOptions: {
        chart: {
          type: 'solidgauge',
        },
        title: null,
        pane: {
          center: ['50%', '85%'],
          size: '100%',
          startAngle: -90,
          endAngle: 90,
          background: {
            backgroundColor: (window.Highcharts.theme && window.Highcharts.theme.background2) || '#EEE',
            innerRadius: '60%',
            outerRadius: '100%',
            shape: 'arc',
          },
        },

        tooltip: {
          enabled: false,
        },

        // the value axis
        yAxis: {
          stops: [
            [0.2, '#55BF3B'], // green
            [0.5, '#DDDF0D'], // yellow
            [0.8, '#DF5353'], // red
          ],
          lineWidth: 0,
          minorTickInterval: null,
          tickAmount: 2,
          title: {
            y: -70,
          },
          labels: {
            y: 16,
          },
        },

        plotOptions: {
          solidgauge: {
            dataLabels: {
              y: 5,
              borderWidth: 0,
              useHTML: true,
            },
          },
        },
      },
    }
  },
  methods: {
    createStatusChart() {
      try {
        const Highcharts = window.Highcharts
        this.chartSpeed = Highcharts.chart(
          'container-speed',
          Highcharts.merge(this.gaugeOptions, {
            yAxis: {
              min: 0,
              max: 100,
              title: {
                text: '런던문자 발송현황',
              },
            },

            credits: {
              enabled: false,
            },

            series: [
              {
                name: 'WaitCount',
                data: [0],
                dataLabels: {
                  format:
                    '<div style="text-align:center"><span style="font-size:25px;color:' +
                    ((Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black') +
                    '">{y}</span><br/>' +
                    '<span style="font-size:12px;color:#333" id="server-status-msg">-</span></div>',
                },
                tooltip: {
                  valueSuffix: '%',
                },
              },
            ],
          })
        )
      }
      catch (e) {
        console.log(e)
      }
    },
    async updateStatus(status) {
      if (!status || !this.chartSpeed) {
        return
      }

      const { waitCount } = status

      var percent = (waitCount / 50000) * 100
      percent = percent < 0 ? 0 : percent
      percent = percent > 100 ? 100 : percent
      percent = parseInt(percent)

      var point = this.chartSpeed.series[0].points[0]
      point.update(percent)

      var statusText = ''
      var statusSubMsg = ''
      if (percent < 20) {
        statusText = '원활'
        statusSubMsg = '현재 빠른 발송이 가능합니다'
      }
      if (percent < 30) {
        statusText = '원활'
        statusSubMsg = '현재 원활한 발송이 가능합니다'
      } else if (percent < 70) {
        statusText = '대기'
        statusSubMsg = '현재 발송이 약간 느릴 수 있습니다'
      } else {
        statusText = '느림'
        statusSubMsg = '현재 발송요청이 밀려있어 발송이 느립니다'
      }

      document.getElementById('server-status-msg').innerText = statusText
      document.getElementById('server-status-sub-msg').innerText = statusSubMsg
    },
  },
  watch: {
    '$store.state.status'() {
      this.updateStatus(this.$store.state.status)
    },
  }
}

</script>
<style scoped>
#chart-wrap {
  margin-left: 8px;
  position: relative;
  width: 240px;
  height: 250px;
  float: left;
}

#server-status-sub-msg {
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 13px;
  white-space: nowrap;
  letter-spacing: -0.2px;
}

#container-speed {
  float: left;
  width: 240px;
  height: 240px;
}
</style>